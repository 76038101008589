import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { TransactionRq } from '../shared/transactionRq.model';
import { SetReportRq } from '../shared/setReportRq.model';
import { environment, environmentDev, environmentPROD, environmentQA, environmentTT, environmentUAT } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppService {
  isBasicAloneDisplayed: boolean = false;
  currentStep: any = 0;
  screenEnclosurePrice: any = 0;
  selectedPackage: string = '';
  dogLiablityAnswer: boolean = false;
  DCJSON: any = {
    "Flags": {
      "QuoteNumber": "",
      "Account": "1",
      "AOI": "0",
      "Coverages": "1",
      "PackageType": "Enhanced"
    },
    "AccountDetails": {
      "Account": {
        "FirstName": "",
        "LastName": "",
        "BirthDate": "",
        "PrimaryPhone": "",
        "Email": "",
        "EffectiveDate": "",
        "BillClass": "",
        "WritingCompany": ""
      },
      "PropertyAddress": {
        "Address1": "",
        "Address2": "",
        "City": "",
        "ZipCode": "",
        "State": "",
        "InfutorSquareFeet": "",
        "InfutorYearBuilt": ""
      },
      "CoApplicant": {
        "FirstName": "",
        "LastName": "",
        "HomePhone": "",
        "BirthDate": "",
      },
      "AOInterest": {
        "Name": "",
        "Address1": "",
        "City": "",
        "ZipCode": "",
        "State": "",
        "LoanNumber": ""
      },
      "UnderwritingQuestions": {
        "isBusinessConducted": "No",
        "isHouseForSale": "No",
        "anyCoverageDeclinedCancelled": "No",
        "isPropertyDisrepair": "No",
        "isPropertyWithin300FtOfCommercialProp": "No"
      }
    },
    "RiskDetails": {
      "QuoteNumber": "",
      "LOBType": "HomeownersAdvantage",
      "Construction": "Masonry",
      "ExteriorWallType": "Brick/Concrete/StoneVeneer",
      "FoundationType": "C",
      "RoofGeometry": "Hip", //roof shape
      "RoofType": "ShinglesArchitectural", //roof material
      "CarportsPoolCagesLimit": "0",
      "CoverageDogLiability": "0",
      "IdentityFraudExpenseCoverage": "0",
      "EquipmentBreakdown": "0",
      "RoofConditionScore": "5",
      "NumberofStories": "1",
      "NumberOfChildren": "1"
    }
  };

  DCMapping = {
    "PROP_CNSTRTYPE": [
      {
        dcAPIField: "Construction",
        dcMatch: "Frame",
        InfutorResponse: ["FRU",
          "FRS",
          "FRY",
          "FST"
        ]
      },
      {
        dcAPIField: "Construction",
        dcMatch: "Masonry",
        InfutorResponse: ["CCB", "CRE",
          "MAS",
          "FRY",
          "FST",
          "undefined"
        ]
      }
    ],
    "PROP_STORIESCD": [
      {
        dcAPIField: "NumberofStories",
        dcMatch: "1",
        InfutorResponse: ["5",
          "7",
          "10",
          "12"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "1.5",
        InfutorResponse: ["15",
          "17"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "2",
        InfutorResponse: ["20",
          "22"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "2.5",
        InfutorResponse: ["25",
          "27"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "3",
        InfutorResponse: ["30",
          "32",
          "35"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "NONE",
        InfutorResponse: ["undefined", "0"
        ]
      }
    ],
    "PROP_EXTNW": [
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "EIFS",
        InfutorResponse: ["EIF"]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "Aluminum/Metal",
        InfutorResponse: ["AFW",
          "CLA",
          "FRM",
          "FRS",
          "FRV",
          "FRW",
          "FSB",
          "FSD",
          "FSG",
          "PWD",
          "PWF",
          "PWP",
          "SGS",
          "SGW",
          "SSW"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "VinylSiding",
        InfutorResponse: ["TLU",
          "TUU",
          "VIN",
          "VIT",
          "VNW"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "Stucco",
        InfutorResponse: ["MSC",
          "OPE",
          "PAB",
          "PAN",
          "PCP",
          "PCW",
          "PER",
          "PFM",
          "PFW",
          "PLS",
          "PLY",
          "POR",
          "PRB",
          "PRC",
          "PRF",
          "PRP",
          "PST",
          "PTP",
          "SEW",
          "SFW",
          "SSC",
          "SSF",
          "SSH",
          "SSS",
          "SST",
          "STM",
          "STO",
          "STP",
          "STT",
          "STU",
          "STV",
          "STW",
          "STY",
          "SUV",
          "SVE",
          "SWD",
          "SWF"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "Other",
        InfutorResponse: ["ADO",
          "CHA",
          "CWL",
          "GLA",
          "SGL"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "Brick/Concrete/StoneVeneer",
        InfutorResponse: ["undefined",
          "ALB",
          "ABL",
          "BAS",
          "BBA",
          "BBD",
          "BBS",
          "BBW",
          "BFS",
          "BFT",
          "BFV",
          "BFW",
          "BLB",
          "BLF",
          "BLM",
          "BLO",
          "BLP",
          "BLS",
          "BLT",
          "BLW",
          "BMG",
          "BMM",
          "BMN",
          "BMS",
          "BMT",
          "BMV",
          "BOP",
          "BRC",
          "BRI",
          "BRM",
          "BRO",
          "BRP",
          "BRS",
          "BRV",
          "BRW",
          "BRX",
          "BSD",
          "BSH",
          "BSO",
          "BST",
          "BSV",
          "BSW",
          "BTL",
          "BVB",
          "BVN",
          "BVW",
          "BWB",
          "BWD",
          "BWS",
          "BWT",
          "BWV",
          "CBC",
          "CBE",
          "CBL",
          "CBM",
          "CBP",
          "CBS",
          "CBU",
          "CBV",
          "CBW",
          "CBY",
          "CCB",
          "CCC",
          "CDR",
          "CFS",
          "CGP",
          "CLT",
          "CMG",
          "CMS",
          "CMT",
          "CMW",
          "CNB",
          "CNT",
          "COM",
          "CON",
          "COS",
          "CRP",
          "CRS",
          "CSH",
          "CSI",
          "CSP",
          "CSS",
          "CST",
          "CUS",
          "FCB",
          "IMI",
          "INC",
          "IRN",
          "MAR,",
          "MAS",
          "MBS",
          "MSH",
          "MSM",
          "MSN",
          "MSS",
          "MST",
          "MSV",
          "MSW",
          "MTP",
          "MTS",
          "MVN",
          "MVS",
          "MYS",
          "MYW",
          "RBW",
          "ROC",
          "SBR",
          "SDS",
          "SHB",
          "SHE",
          "SHI",
          "SHS",
          "SHW",
          "SIN",
          "SLU",
          "SNS",
          "SNW",
          "SPR",
          "SSD",
          "SSG",
          "SVF",
          "SVL",
          "SVW",
          "SWS",
          "TAP",
          "TIL",
          "XXX",
          "QUA",
          "ADC",
          "AFR",
          "AIR",
          "ANT",
          "BIL",
          "BUN",
          "DOM",
          "DUP",
          "EUR",
          "FAR",
          "FED",
          "GAM",
          "GEO",
          "GOT",
          "GRA",
          "GRE",
          "GRG",
          "GRS",
          "HRI",
          "HSH",
          "LRI",
          "LSH",
          "MAN",
          "MDR",
          "MED",
          "MIS",
          "MLF",
          "MLL",
          "MOB",
          "MOD",
          "MRI",
          "NEN",
          "OLD",
          "OLE",
          "PAT",
          "QLV",
          "RAM",
          "RAN",
          "REC",
          "ROW",
          "RRA",
          "SLT",
          "SPA",
          "SPF",
          "SPL",
          "SQU",
          "STR",
          "SWN",
          "TLV",
          "TPX",
          "TRA",
          "TRN",
          "TUD",
          "TWN",
          "UND",
          "USH",
          "VIC",
          "WIL",
          "WSH"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "HardBoard/FiberBoard",
        InfutorResponse: ["ASD", "FST", "FAB",
          "FAV",
          "FBR",
          "FCL",
          "FHB",
          "FIB",
          "FMM",
          "FMV",
          "FPM",
          "FPP",
          "FRA",
          "FRB",
          "FRC",
          "FRE",
          "FRG",
          "FSL",
          "FSM",
          "FSP",
          "FSS",
          "FST",
          "FSV",
          "FSW",
          "FTS",
          "FTW",
          "FWB",
          "FWC",
          "FWD",
          "FWS",
          "HBP",
          "HBV",
          "HRW",
          "LOG",
          "LPS",
          "LWS",
          "WAF",
          "WAS",
          "WDA",
          "WDF",
          "WDH",
          "WDS",
          "WDV",
          "WFA",
          "WFS",
          "WFV",
          "WFX",
          "WLB",
          "WOO",
          "WOS",
          "WRB",
          "WSS",
          "WWL",
          "WWS",
          "CAB",
          "CAP",
          "CEN",
          "CHL",
          "CLU",
          "CND",
          "COA",
          "COL",
          "COV",
          "CTB",
          "DES"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "Aluminum/Metal",
        InfutorResponse: [
          "ABL",
          "ALF",
          "ALM",
          "ALN",
          "ALS",
          "ALU",
          "ALV",
          "AME",
          "AMS",
          "ASO",
          "GLL",
          "GLM",
          "GLS",
          "GMA",
          "GST",
          "GUN",
          "GWD",
          "HAR",
          "HAY",
          "MEL",
          "MET",
          "MIX",
          "MLS",
          "MWD",
          "MWF",
          "STE",
          "STF"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "Asbestos",
        InfutorResponse: [
          "ABP",
          "ABR",
          "ABV",
          "ABW",
          "ASB",
          "ASC",
          "ASF",
          "ASG",
          "ASI",
          "ASK",
          "ASM",
          "ASP",
          "AST",
          "ASV",
          "ASW",
          "AWD"
        ]
      }
    ],
    "PROP_FND": [
      {
        dcAPIField: "FoundationType",
        dcMatch: "C",
        InfutorResponse: ["undefined", "0", "999",
          "BLK",
          "BRK",
          "CNB",
          "CNF",
          "CNW",
          "CRB",
          "CRE",
          "CRS",
          "MSN",
          "PPI",
          "PRE",
          "SDF",
          "SLB",
          "STD",
          "STE",
          "STM",
          "STN",
          "UBM",
          "UCR",
          "UPB",
          "WOO"
        ]
      },
      {
        dcAPIField: "FoundationType",
        dcMatch: "O3",
        InfutorResponse: ["DRE", "GDB", "RAC", "RAS"]
      },
      {
        dcAPIField: "FoundationType",
        dcMatch: "O4",
        InfutorResponse: ["MUD"]
      },
      {
        dcAPIField: "FoundationType",
        dcMatch: "O2",
        InfutorResponse: ["PIL", "PIR"]
      }
    ],
    // "PROP_ROOFTYPE": [
    //   {
    //     dcAPIField: "RoofGeometry",
    //     dcMatch: "Hip",
    //     InfutorResponse: [
    //       "999",
    //       "9A0",
    //       "9B0",
    //       "A",
    //       "B",
    //       "C",
    //       "D",
    //       "E",
    //       "H",
    //       "I",
    //       "J",
    //       "K",
    //       "L",
    //       "M",
    //       "N",
    //       "O",
    //       "P",
    //       "Q",
    //       "R",
    //       "S",
    //       "T",
    //       "U",
    //       "V",
    //       "W",
    //       "X",
    //       "Y",
    //       "Z",
    //       "undefined"]
    //   },
    //   {
    //     dcAPIField: "RoofGeometry",
    //     dcMatch: "Gable",
    //     InfutorResponse: ["G"]
    //   },
    //   {
    //     dcAPIField: "RoofGeometry",
    //     dcMatch: "Flat",
    //     InfutorResponse: ["F"]
    //   }

    // ],
    "PROP_ROOFCOVER": [
      {
        dcAPIField: "RoofType",
        dcMatch: "ShinglesAsphaltOrComposition",
        InfutorResponse: ["undefined",
          "131",
          "133",
          "999",
          "0",
          "5",
          "13",
          "14",
          "15",
          "30"
        ]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "TileFlatConcrete,Clay,OrComposite",
        InfutorResponse: ["16",
          "27",
          "121",
          "122",
          "123",
          "124",
          "125",
          "126",
          "127",
          "132",
          "135",
          "136",
          "145",
          "150",
          "151",
          "152",
          "154",
          "163",
          "165",
          "166",
          "167",
          "169",
          "173",
          "174",
          "175",
          "176",
          "177",
          "178"
        ]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "ShinglesArchitectural",
        InfutorResponse: ["2",
          "22",
          "23",
          "24"
        ]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Other",
        InfutorResponse: ["26"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "MetalLightPanelsSlate",
        InfutorResponse: ["4",
          "17",
          "18",
          "19",
          "20",
          "21",
          "101",
          "138",
          "139",
          "141",
          "142",
          "143",
          "144",
          "146",
          "147",
          "148",
          "155",
          "157",
          "158",
          "159",
          "160",
          "161",
          "162",
          "164",
          "179"
        ]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Built-UpRoofBallasted",
        InfutorResponse: ["3"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Built-UpConcreteFill",
        InfutorResponse: ["25",
          "106",
          "107",
          "109",
          "110",
          "111"
        ]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Built-UpSinglePlyMembrane-RoofBallasted",
        InfutorResponse: ["113",
          "114",
          "115",
          "116",
          "117",
          "118",
          "119",
          "120"
        ]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Asbestos",
        InfutorResponse: ["10",
          "11",
          "28",
          "29",
          "104",
          "105",
          "140",
          "170"
        ]
      }
    ]
  };

  DCMappingTX = {
    "PROP_CNSTRTYPE": [
      {
        dcAPIField: "Construction",
        dcMatch: "Frame",
        InfutorResponse: ["FRU",
          "FRS",
          "FRY",
          "FST"
        ]
      },
      {
        dcAPIField: "Construction",
        dcMatch: "Masonry",
        InfutorResponse: ["CCB", "CRE",
          "MAS",
          "FRY",
          "FST",
          "undefined"
        ]
      }
    ],
    "PROP_STORIESCD": [
      {
        dcAPIField: "NumberofStories",
        dcMatch: "1",
        InfutorResponse: ["5",
          "7",
          "10",
          "12"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "1.5",
        InfutorResponse: ["15",
          "17"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "2",
        InfutorResponse: ["20",
          "22"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "2.5",
        InfutorResponse: ["25",
          "27"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "3",
        InfutorResponse: ["30",
          "32",
          "35"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "NONE",
        InfutorResponse: ["undefined", "0"
        ]
      }
    ],
    "PROP_EXTNW": [
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "EIFS",
        InfutorResponse: ["EIF"]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "AluminumMetal",
        InfutorResponse: ["AFW",
          "CLA",
          "FRM",
          "FRS",
          "FRV",
          "FRW",
          "FSB",
          "FSD",
          "FSG",
          "PWD",
          "PWF",
          "PWP",
          "SGS",
          "SGW",
          "SSW"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "VinylSiding",
        InfutorResponse: ["TLU",
          "TUU",
          "VIN",
          "VIT",
          "VNW"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "Stucco",
        InfutorResponse: ["MSC",
          "OPE",
          "PAB",
          "PAN",
          "PCP",
          "PCW",
          "PER",
          "PFM",
          "PFW",
          "PLS",
          "PLY",
          "POR",
          "PRB",
          "PRC",
          "PRF",
          "PRP",
          "PST",
          "PTP",
          "SEW",
          "SFW",
          "SSC",
          "SSF",
          "SSH",
          "SSS",
          "SST",
          "STM",
          "STO",
          "STP",
          "STT",
          "STU",
          "STV",
          "STW",
          "STY",
          "SUV",
          "SVE",
          "SWD",
          "SWF"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "Other",
        InfutorResponse: ["ADO",
          "CHA",
          "CWL",
          "GLA",
          "SGL"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "BrickConcreteStoneVeneer",
        InfutorResponse: ["undefined",
          "ALB",
          "ABL",
          "BAS",
          "BBA",
          "BBD",
          "BBS",
          "BBW",
          "BFS",
          "BFT",
          "BFV",
          "BFW",
          "BLB",
          "BLF",
          "BLM",
          "BLO",
          "BLP",
          "BLS",
          "BLT",
          "BLW",
          "BMG",
          "BMM",
          "BMN",
          "BMS",
          "BMT",
          "BMV",
          "BOP",
          "BRC",
          "BRI",
          "BRM",
          "BRO",
          "BRP",
          "BRS",
          "BRV",
          "BRW",
          "BRX",
          "BSD",
          "BSH",
          "BSO",
          "BST",
          "BSV",
          "BSW",
          "BTL",
          "BVB",
          "BVN",
          "BVW",
          "BWB",
          "BWD",
          "BWS",
          "BWT",
          "BWV",
          "CBC",
          "CBE",
          "CBL",
          "CBM",
          "CBP",
          "CBS",
          "CBU",
          "CBV",
          "CBW",
          "CBY",
          "CCB",
          "CCC",
          "CDR",
          "CFS",
          "CGP",
          "CLT",
          "CMG",
          "CMS",
          "CMT",
          "CMW",
          "CNB",
          "CNT",
          "COM",
          "CON",
          "COS",
          "CRP",
          "CRS",
          "CSH",
          "CSI",
          "CSP",
          "CSS",
          "CST",
          "CUS",
          "FCB",
          "IMI",
          "INC",
          "IRN",
          "MAR,",
          "MAS",
          "MBS",
          "MSH",
          "MSM",
          "MSN",
          "MSS",
          "MST",
          "MSV",
          "MSW",
          "MTP",
          "MTS",
          "MVN",
          "MVS",
          "MYS",
          "MYW",
          "RBW",
          "ROC",
          "SBR",
          "SDS",
          "SHB",
          "SHE",
          "SHI",
          "SHS",
          "SHW",
          "SIN",
          "SLU",
          "SNS",
          "SNW",
          "SPR",
          "SSD",
          "SSG",
          "SVF",
          "SVL",
          "SVW",
          "SWS",
          "TAP",
          "TIL",
          "XXX",
          "QUA",
          "ADC",
          "AFR",
          "AIR",
          "ANT",
          "BIL",
          "BUN",
          "DOM",
          "DUP",
          "EUR",
          "FAR",
          "FED",
          "GAM",
          "GEO",
          "GOT",
          "GRA",
          "GRE",
          "GRG",
          "GRS",
          "HRI",
          "HSH",
          "LRI",
          "LSH",
          "MAN",
          "MDR",
          "MED",
          "MIS",
          "MLF",
          "MLL",
          "MOB",
          "MOD",
          "MRI",
          "NEN",
          "OLD",
          "OLE",
          "PAT",
          "QLV",
          "RAM",
          "RAN",
          "REC",
          "ROW",
          "RRA",
          "SLT",
          "SPA",
          "SPF",
          "SPL",
          "SQU",
          "STR",
          "SWN",
          "TLV",
          "TPX",
          "TRA",
          "TRN",
          "TUD",
          "TWN",
          "UND",
          "USH",
          "VIC",
          "WIL",
          "WSH"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "HardBoardFiberBoard",
        InfutorResponse: ["ASD", "FST", "FAB",
          "FAV",
          "FBR",
          "FCL",
          "FHB",
          "FIB",
          "FMM",
          "FMV",
          "FPM",
          "FPP",
          "FRA",
          "FRB",
          "FRC",
          "FRE",
          "FRG",
          "FSL",
          "FSM",
          "FSP",
          "FSS",
          "FST",
          "FSV",
          "FSW",
          "FTS",
          "FTW",
          "FWB",
          "FWC",
          "FWD",
          "FWS",
          "HBP",
          "HBV",
          "HRW",
          "LOG",
          "LPS",
          "LWS",
          "WAF",
          "WAS",
          "WDA",
          "WDF",
          "WDH",
          "WDS",
          "WDV",
          "WFA",
          "WFS",
          "WFV",
          "WFX",
          "WLB",
          "WOO",
          "WOS",
          "WRB",
          "WSS",
          "WWL",
          "WWS",
          "CAB",
          "CAP",
          "CEN",
          "CHL",
          "CLU",
          "CND",
          "COA",
          "COL",
          "COV",
          "CTB",
          "DES"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "AluminumMetal",
        InfutorResponse: [
          "ABL",
          "ALF",
          "ALM",
          "ALN",
          "ALS",
          "ALU",
          "ALV",
          "AME",
          "AMS",
          "ASO",
          "GLL",
          "GLM",
          "GLS",
          "GMA",
          "GST",
          "GUN",
          "GWD",
          "HAR",
          "HAY",
          "MEL",
          "MET",
          "MIX",
          "MLS",
          "MWD",
          "MWF",
          "STE",
          "STF"
        ]
      },
      {
        dcAPIField: "ExteriorWallType",
        dcMatch: "Asbestos",
        InfutorResponse: [
          "ABP",
          "ABR",
          "ABV",
          "ABW",
          "ASB",
          "ASC",
          "ASF",
          "ASG",
          "ASI",
          "ASK",
          "ASM",
          "ASP",
          "AST",
          "ASV",
          "ASW",
          "AWD"
        ]
      }
    ],
    "PROP_FND": [
      {
        dcAPIField: "FoundationType",
        dcMatch: "ClosedConcreteSlab",
        InfutorResponse: ["undefined", "0", "999",
          "BLK",
          "BRK",
          "CNB",
          "CNF",
          "CNW",
          "CRB",
          "CRE",
          "CRS",
          "MSN",
          "PPI",
          "PRE",
          "SDF",
          "SLB",
          "STD",
          "STE",
          "STM",
          "STN",
          "UBM",
          "UCR",
          "UPB",
          "WOO"
        ]
      },
      {
        dcAPIField: "FoundationType",
        dcMatch: "CrawlSpaceEnclosedwithnonpermanentmaterialsLatticeetc",
        InfutorResponse: ["DRE", "GDB", "RAC", "RAS"]
      },
      {
        dcAPIField: "FoundationType",
        dcMatch: "ClosedConcreteSlab",
        InfutorResponse: ["MUD"]
      },
      {
        dcAPIField: "FoundationType",
        dcMatch: "PilingsPiersorStilts810elevations",
        InfutorResponse: ["PIL", "PIR"]
      }
    ],
    // "PROP_ROOFTYPE": [
    //   {
    //     dcAPIField: "RoofGeometry",
    //     dcMatch: "Hip",
    //     InfutorResponse: [
    //       "999",
    //       "9A0",
    //       "9B0",
    //       "A",
    //       "B",
    //       "C",
    //       "D",
    //       "E",
    //       "H",
    //       "I",
    //       "J",
    //       "K",
    //       "L",
    //       "M",
    //       "N",
    //       "O",
    //       "P",
    //       "Q",
    //       "R",
    //       "S",
    //       "T",
    //       "U",
    //       "V",
    //       "W",
    //       "X",
    //       "Y",
    //       "Z",
    //       "undefined"]
    //   },
    //   {
    //     dcAPIField: "RoofGeometry",
    //     dcMatch: "Gable",
    //     InfutorResponse: ["G"]
    //   },
    //   {
    //     dcAPIField: "RoofGeometry",
    //     dcMatch: "Flat",
    //     InfutorResponse: ["F"]
    //   }

    // ],
    "PROP_ROOFCOVER": [
      {
        dcAPIField: "RoofType",
        dcMatch: "AluminumCorrugated",
        InfutorResponse: ["4"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Asbestos",
        InfutorResponse: ["10",
          "11",
          "104",
          "105",
          "140",
          "170"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "CompositeShingle",
        InfutorResponse: ["13",
          "14",
          "15",
          "131"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "ConcreteTile",
        InfutorResponse: ["121",
          "122",
          "123",
          "124",
          "125",
          "126",
          "127"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Metal",
        InfutorResponse: ["17",
          "18",
          "19",
          "20",
          "21",
          "101",
          "138",
          "139",
          "141",
          "142",
          "143",
          "144",
          "146",
          "147",
          "148",
          "155",
          "157",
          "158",
          "159",
          "160",
          "161",
          "162",
          "164",
          "179"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Other",
        InfutorResponse: ["3",
          "113",
          "114",
          "115",
          "118",
          "119"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "PouredConcrete",
        InfutorResponse: ["106",
          "107",
          "109",
          "110",
          "111",
          "135",
          "136"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "ShinglesArchitectural",
        InfutorResponse: ["undefined", "0",
          "2",
          "22",
          "23",
          "24",
          "133",
          "999"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Slate",
        InfutorResponse: ["174"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "SuperiorPouredConcrete",
        InfutorResponse: ["5", "16", "163"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "TarGravel",
        InfutorResponse: ["25",
          "116",
          "117",
          "145",
          "151",
          "152",
          "165",
          "166",
          "167",
          "169",
          "173",
          "175",
          "176",
          "177",
          "178"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "TarShingle",
        InfutorResponse: ["26"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Tile",
        InfutorResponse: ["27", "132", "150"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "WoodShake",
        InfutorResponse: ["28", "29", "120"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "WoodShingle",
        InfutorResponse: ["30", "154"]
      }
    ]
  }

  DCMappingLA = {
    "PROP_CNSTRTYPE": [
      {
        dcAPIField: "Construction",
        dcMatch: "Frame",
        InfutorResponse: ["FRU",
          "FRS",
          "FRY",
          "FST"
        ]
      },
      {
        dcAPIField: "Construction",
        dcMatch: "Masonry",
        InfutorResponse: ["CCB", "CRE",
          "MAS",
          "FRY",
          "FST",
          "undefined"
        ]
      }
    ],
    "PROP_STORIESCD": [
      {
        dcAPIField: "NumberofStories",
        dcMatch: "1",
        InfutorResponse: ["5",
          "7",
          "10",
          "12"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "1.5",
        InfutorResponse: ["15",
          "17"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "2",
        InfutorResponse: ["20",
          "22"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "2.5",
        InfutorResponse: ["25",
          "27"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "3",
        InfutorResponse: ["30",
          "32",
          "35"
        ]
      },
      {
        dcAPIField: "NumberofStories",
        dcMatch: "NONE",
        InfutorResponse: ["undefined", "0"
        ]
      }
    ],
    "PROP_EXTNW": [],
    "PROP_FND": [
      {
        dcAPIField: "FoundationType",
        dcMatch: "ClosedConcreteSlab",
        InfutorResponse: ["undefined", "0", "999",
          "BLK",
          "BRK",
          "CNB",
          "CNF",
          "CNW",
          "CRB",
          "CRE",
          "CRS",
          "MSN",
          "PPI",
          "PRE",
          "SDF",
          "SLB",
          "STD",
          "STE",
          "STM",
          "STN",
          "UBM",
          "UCR",
          "UPB",
          "WOO"
        ]
      },
      {
        dcAPIField: "FoundationType",
        dcMatch: "CrawlSpaceEnclosedwithnonpermanentmaterialsLatticeetc",
        InfutorResponse: ["DRE", "GDB", "RAC", "RAS"]
      },
      {
        dcAPIField: "FoundationType",
        dcMatch: "ClosedConcreteSlab",
        InfutorResponse: ["MUD"]
      },
      {
        dcAPIField: "FoundationType",
        dcMatch: "PilingsPiersorStilts810elevations",
        InfutorResponse: ["PIL", "PIR"]
      }
    ],
    // "PROP_ROOFTYPE": [
    //   {
    //     dcAPIField: "RoofGeometry",
    //     dcMatch: "Hip",
    //     InfutorResponse: [
    //       "999",
    //       "9A0",
    //       "9B0",
    //       "A",
    //       "B",
    //       "C",
    //       "D",
    //       "E",
    //       "H",
    //       "I",
    //       "J",
    //       "K",
    //       "L",
    //       "M",
    //       "N",
    //       "O",
    //       "P",
    //       "Q",
    //       "R",
    //       "S",
    //       "T",
    //       "U",
    //       "V",
    //       "W",
    //       "X",
    //       "Y",
    //       "Z",
    //       "undefined"]
    //   },
    //   {
    //     dcAPIField: "RoofGeometry",
    //     dcMatch: "Gable",
    //     InfutorResponse: ["G"]
    //   },
    //   {
    //     dcAPIField: "RoofGeometry",
    //     dcMatch: "Flat",
    //     InfutorResponse: ["F"]
    //   }

    // ],
    "PROP_ROOFCOVER": [
      {
        dcAPIField: "RoofType",
        dcMatch: "AluminumCorrugated",
        InfutorResponse: ["4"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Asbestos",
        InfutorResponse: ["10",
          "11",
          "104",
          "105",
          "140",
          "170"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "CompositeShingle",
        InfutorResponse: ["13",
          "14",
          "15",
          "131"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "ConcreteTile",
        InfutorResponse: ["121",
          "122",
          "123",
          "124",
          "125",
          "126",
          "127"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Metal",
        InfutorResponse: ["17",
          "18",
          "19",
          "20",
          "21",
          "101",
          "138",
          "139",
          "141",
          "142",
          "143",
          "144",
          "146",
          "147",
          "148",
          "155",
          "157",
          "158",
          "159",
          "160",
          "161",
          "162",
          "164",
          "179"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Other",
        InfutorResponse: ["3",
          "113",
          "114",
          "115",
          "118",
          "119"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "PouredConcrete",
        InfutorResponse: ["106",
          "107",
          "109",
          "110",
          "111",
          "135",
          "136"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "ShinglesArchitectural",
        InfutorResponse: ["undefined", "0",
          "2",
          "22",
          "23",
          "24",
          "133",
          "999"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Slate",
        InfutorResponse: ["174"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "SuperiorPouredConcrete",
        InfutorResponse: ["5", "16", "163"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "TarGravel",
        InfutorResponse: ["25",
          "116",
          "117",
          "145",
          "151",
          "152",
          "165",
          "166",
          "167",
          "169",
          "173",
          "175",
          "176",
          "177",
          "178"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "TarShingle",
        InfutorResponse: ["26"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "Tile",
        InfutorResponse: ["27", "132", "150"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "WoodShake",
        InfutorResponse: ["28", "29", "120"]
      },
      {
        dcAPIField: "RoofType",
        dcMatch: "WoodShingle",
        InfutorResponse: ["30", "154"]
      }
    ]
  };
  env: any = this.getEnvironment();

  constructor(private http: HttpClient) {
    console.log(this.env);
  }

  getEnvironment() {
    let domain = this.domain_from_url(window.location.origin);
    if (domain == 'policy.pmiddleware.safepointdc.com') {
      return environmentPROD;
    }
    else if (domain == 'uatpolicy.preprodmiddleware.safepointdc.com') {
      return environmentUAT;
    }
    else if (domain == 'ttpolicy.middleware.safepointdc.com') {
      return environmentTT;
    }
    else if (domain == 'qapolicy.middleware.safepointdc.com') {
      return environmentQA;
    }
    else if (domain == 'devpolicy.middleware.safepointdc.com') {
      return environmentDev;
    }
    else {
      return environment;
    }
  }
  
  domain_from_url(url:any) {
    var result
    var match
    if (match = url.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/im)) {
      result = match[1];
    }
    return result
  }
  
  api: string = this.env.baseUrl;

  getAddressByLatLang(lat: any, lang: any) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lang}&key=${atob(this.env.googleKEY)}`);
  }
  getLatLangByAddress(address: any) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${atob(this.env.googleKEY)}`);
  }
  dataFinder(d_address: any, d_city: any, d_state: any, d_zip: any) {
    let headers = new HttpHeaders();
    headers = headers.set('x-versium-api-key', this.env.versiumAPIKEY);

    return this.http.get(`${this.env.datafinderURL}/demographic?output[]=demographic&address=${d_address}&city=${d_city}&state=${d_state}&zip=${d_zip}`, { headers: headers });
  }
  dataFinderContact(d_address: any, d_city: any, d_state: any, d_zip: any) {
    let headers = new HttpHeaders();
    headers = headers.set('x-versium-api-key', this.env.versiumAPIKEY);

    return this.http.get(`${this.env.datafinderURL}/contact?output[]=email&output[]=phone&address=${d_address}&city=${d_city}&state=${d_state}&zip=${d_zip}`, { headers: headers });
  }
  // infutorAPI(address: any) {
  //   return this.http.get(`${this.env.infutorURL}/IDProfileProperty?Login=safepointxml&Password=TzEK5HS6CLWR06~&Address1=${address.address}&City=${address.city}&State=${address.state}&Zip=${address.zip}`, { responseType: 'text' });
  // }

  infutorAPI(address: any) {
    return this.http.get(`${this.env.dcAPI}InfutorAPI?Address1=${address.address}&City=${address.city}&State=${address.state}&Zip=${address.zip}`, { responseType: 'text' });
  }
  
  createQuote(data: any): any {
    localStorage.setItem("DCJSONLOCAL", JSON.stringify(data));
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', "application/json");
    return this.http.post(this.env.dcAPI + 'CreateQuote', data, { headers: headers });
  }
  updateQuote(data: any): any {
    localStorage.setItem("DCJSONLOCAL", JSON.stringify(data));
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', "application/json");
    data.Flags.PackageType = this.selectedPackage;
    return this.http.post(this.env.dcAPI + 'UpdateQuote', data, { headers: headers });
  }
  bindQuote(data: any): any {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', "application/json");
    return this.http.post(this.env.dcAPI + `BindQuote?QuoteNumber=${data.quoteNumber}&IsMortgage=${data.isMortgage}&State=${data.state}`, {}, { headers: headers });
  }
  loadMapJs() {
    let gapi = atob(this.env.googleKEY);
    const node = document.createElement('script');
    node.src = `https://maps.googleapis.com/maps/api/js?key=${gapi}&libraries=places`;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  getGICToken() {
    let apiUrl = `${this.env.gicAPI}/auth/Login?username=${atob(this.env.gicUserName)}&password=${atob(this.env.gicPassword)}`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe((res: any) => {
        if (res && res.token) {
          localStorage.setItem("GIC_TOKEN", res.token);
          localStorage.setItem("GIC_TOKEN_EXPIRY", res.expiration_date);
          resolve(res.token);
        } else {
          reject(null);
        }
      }, (error) => {
        reject(null);
      });
    });
  }
  gicAPI(token: any, lat: any, long: any) {
    //get token
    return this.http.get(`${this.env.gicAPI}/property/GetPropertyInformation?search_radius=10&wkt=POINT(${long} ${lat})&token=${token}`);
  }
  gicAPIWithrequest(token: any, lat: any, long: any) {
    //get token
    return this.http.get(`${this.env.gicAPI}/property/GetPropertyInformation?wkt=POINT(${long} ${lat})&token=${token}`);
  }
  checkIfAOInterestEmpty() {
    let empty = true;
    if (this.DCJSON.AccountDetails.AOInterest) {
      for (const [key, value] of Object.entries(this.DCJSON.AccountDetails.AOInterest)) {
        if (value != "") {
          empty = false;
          break;
        }
      }
    }

    return empty;
  }
  checkIfCoApplicantEmpty() {
    let empty = true;
    if (this.DCJSON.AccountDetails.CoApplicant) {
      for (const [key, value] of Object.entries(this.DCJSON.AccountDetails.CoApplicant)) {
        if (value != "") {
          empty = false;
          break;
        }
      }
    }
    return empty;
  }


  validatePayment(transactionRqObj: TransactionRq): any {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', "application/json");
    return this.http.post(this.env.dcAPI + `BindQuote?QuoteNumber=${transactionRqObj.QuoteNumber}&TransactionId=${transactionRqObj.TransactionId}&EmailId=${transactionRqObj.EmailId}&IsMortgage=${transactionRqObj.IsMortgage == '1' ? true : false}&State=${transactionRqObj.State}`, {}, { headers: headers });
  }

  setReport(message:string): any {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', "application/json");
    let setReportRq : SetReportRq = new SetReportRq;
    setReportRq.address = this.DCJSON.AccountDetails.PropertyAddress["OriginalAddress"];
    setReportRq.description = message;
    setReportRq.Name = this.DCJSON.AccountDetails.Account["FirstName"] + " " + this.DCJSON.AccountDetails.Account["LastName"];
    setReportRq.Email = this.DCJSON.AccountDetails.Account["Email"];
    setReportRq.EffectiveDate = this.DCJSON.AccountDetails.Account["EffectiveDate"];
    setReportRq.PhoneNumber = this.DCJSON.AccountDetails.Account["PrimaryPhone"];
    setReportRq.quotePolicyNumber = JSON.parse(localStorage.getItem('DC_RESPONSE') || '').quoteNumber;
    return this.http.post(this.env.dcAPI + `SetReport`, setReportRq, { headers: headers });
  }

  // postSignature(data:any){
  //   return this.http.post(environment.signatureUrl + `PostSignature?code=${environment.signatureAccessCode}`, data);
  // }

  postSubscriberAgreement(PolicyNumber:any){
    return this.http.get(this.env.dcAPI + `PostSignature?PolicyNumber=${PolicyNumber}`);
  }

  uploadSubscriberAgreement(data:any){
    return this.http.post(this.env.dcAPI + 'UploadSubscriberAgreement',data);
  }
}
