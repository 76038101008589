export const environment = {
  production: false,
  baseUrl: "https://devpolicy.middleware.safepointdc.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://devpolicy.middleware.safepointdc.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "BxQRLzGjnDJIGO7j8vef2N3Znqyt13eW",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://devpolicy.middleware.safepointdc.com/paymentProcessAeg",

  CApaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  

  MNpaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee",
  MNpaymentStatusPage: "Portal|https://devpolicy.middleware.safepointdc.com/paymentProcessAeg",
};

export const environmentDev = {
  production: false,
  baseUrl: "https://devpolicy.middleware.safepointdc.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://devpolicy.middleware.safepointdc.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "BxQRLzGjnDJIGO7j8vef2N3Znqyt13eW",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://devpolicy.middleware.safepointdc.com/paymentProcessAeg",

  CApaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee"
};

export const environmentQA = {
  production: false,
  baseUrl: "https://qapolicy.middleware.safepointdc.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://qapolicy.middleware.safepointdc.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "0db99ca866254fc9b234c16207816e5d",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://qabilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://qapolicy.middleware.safepointdc.com/paymentProcessAeg",

  CApaymentResponseUrl: "https://qabilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://qabilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee",
};

export const environmentTT = {
  production: false,
  baseUrl: "https://ttpolicy.middleware.safepointdc.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://ttpolicy.middleware.safepointdc.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "xYTFkLm4zPDJ3H8Q7vef1P5Znqrt12vW",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://ttbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://ttpolicy.middleware.safepointdc.com/paymentProcessAeg",

  CApaymentResponseUrl: "https://ttbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://ttbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee"
};

export const environmentUAT = {
  production: false,
  baseUrl: "https://uatpolicy.preprodmiddleware.safepointdc.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://uatpolicy.preprodmiddleware.safepointdc.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "GxQRLzJjnDJIYO7j67vKf2N3Zbtyt13e",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://uatbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://uatpolicy.preprodmiddleware.safepointdc.com/paymentProcessAeg",

  CApaymentResponseUrl: "https://uatbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",

  MNpaymentResponseUrl: "https://uatbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee",
};

export const environmentPROD = {
  production: true,
  baseUrl: "https://policy.pmiddleware.safepointdc.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://policy.pmiddleware.safepointdc.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "Qw7eR1TdUb9LoXc0VfA3zGpJm6N8kWi",

  SFpaymentURL: "https://internet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "Cn0vEONzWC88y/LPSAcIo5hBN9xid/vXKke2vhGfs9s=",
  CApaymentURL: "https://internet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "D9m0NyBO2PlIEiQsgSzNYRzxdxuy64B0gBHLyUE85Sk=",
  MNpaymentURL: "https://internet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "Cn0vEONzWC88y/LPSAcIo5hBN9xid/vXKke2vhGfs9s=",

  SFpaymentResponseUrl: "https://billing.pmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://policy.pmiddleware.safepointdc.com/paymentProcessAeg",
  CApaymentResponseUrl: "https://billing.pmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://billing.pmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee",
};